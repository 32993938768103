import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'id',
    label: 'common.transaction_id',
    width: '20%',
  },
  {
    id: 'username',
    label: 'masterClass.username',
    width: '20%',
  },
  {
    id: 'masterClass',
    label: 'masterClass.masterClass',
    width: '20%',
  },
  {
    id: 'price',
    label: 'masterClass.amount',
    width: '20%',
  },
  {
    id: 'planName',
    label: 'common.plan_name',
    width: '20%',
  },
  {
    id: 'date',
    label: 'masterClass.date',
    width: '20%',
    isNotSortable: true,
  },
];

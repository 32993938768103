import React from 'react';
import { MenuInterface } from '../components/interfaces/menu';
import { PATHS } from './Paths';
import DashboardIcon from '../components/svgComponents/Dashboard';
import DatabaseIcon from '../components/svgComponents/Database';
import ProfileIcon from '../components/svgComponents/Profiles';
import SubadminIcon from '../components/svgComponents/Subadmin';
import BugsIcon from '../components/svgComponents/Bugs';
import UniversityIcon from '../components/svgComponents/University';
import OpportunitiesIcon from '../components/svgComponents/Opportunities';
import ArticleIcon from '../components/svgComponents/Article';
import ReportsIcon from '../components/svgComponents/Reports';
import UserIcon from '../components/svgComponents/Users';
import AdsIcon from '../components/svgComponents/Ads';
import SettingsIcon from '../components/svgComponents/Settings';
import CampaignIcon from '../components/svgComponents/Campaign';
import InterestIcon from '../components/svgComponents/InterestIcon';
import MasterClassIcon from '../components/svgComponents/MasterClassIcon';
import SubscriptionPlanIcon from '../components/svgComponents/SubscriptionPlan';
import TransactionIcon from '../components/svgComponents/Transaction';
import WelcomeVideoIcon from '../components/svgComponents/WelcomeVideo';
import PortfolioPlanIcon from '../components/svgComponents/PortfolioPlanIcon';

export const menuAdmin: MenuInterface[] = [
  {
    title: 'Tableau de bord',
    badge: 'DASHBOARD',
    subtitle: 'Dashboard',
    isSubMenu: false,
    url: PATHS.Dashboard,
    icon: <DashboardIcon />,
  },
  {
    title: 'Vidéo de bienvenue',
    badge: 'WELCOME_VIDEO',
    subtitle: 'WelcomeVideo',
    isSubMenu: false,
    url: PATHS.welcome,
    icon: <WelcomeVideoIcon />,
  },
  {
    title: 'Utilisateurs',
    badge: 'UTILISATEURS',
    subtitle: 'Users',
    isSubMenu: true,
    icon: <UserIcon />,
    subItems: [
      {
        title: 'Administrateurs',
        url: PATHS.Admins,
        badge: 'ADMINS',
        subtitle: 'Admins',
      },
      // { title: 'Artistes', url: PATHS.Artistes, badge: 'ARTISTES', subtitle: 'Artistes' },
      // { title: 'Galeries', url: PATHS.Galleries, badge: 'GALERIES', subtitle: 'Galeries' },
      // { title: 'Art Lovers', url: PATHS.Artlovers, badge: 'ART LOVERS', subtitle: 'Artlovers' },
      { title: 'Utilisatrices', url: PATHS.Artusers, badge: 'ARTS', subtitle: 'Artusers' },
      { title: 'Arts de la semaine', url: PATHS.Arts, badge: 'ARTS', subtitle: 'Arts' },
      { title: 'Artiste de la semaine', url: PATHS.Artworks, badge: 'ARTSWORKS', subtitle: 'Artsworks' },
      { title: `Utilisateurs d'annonces`, url: PATHS.AdsUsers, badge: 'ADS USERS', subtitle: 'Adsusers' },
    ],
  },
  {
    title: 'Signalements',
    badge: 'SIGNALEMENTS',
    subtitle: 'Signalments',
    isSubMenu: true,
    icon: <ReportsIcon />,
    subItems: [
      {
        title: `Rapports d'utilisateurs`,
        url: PATHS.UserReports,
        badge: 'SIGNALEMENTS',
        subtitle: 'Signalments',
      },
      { title: 'Rapports de revenus', url: PATHS.RevenueReports, badge: 'REVENUEREPORTS', subtitle: 'RevenueReports' },
      { title: 'Rapports sur les annonces', url: PATHS.AdsReports, badge: 'ADSREPORTS', subtitle: 'AdsReports' },
      { title: 'Rapports de classe', url: PATHS.ClassroomReports, badge: 'CLASSROOMREPORTS', subtitle: 'ClassroomReports' },
    ],
  },
  {
    title: 'Transactions',
    badge: 'TRANSACTIONS',
    subtitle: 'Transactions',
    isSubMenu: false,
    url: PATHS.Transactions,
    icon: <TransactionIcon />,
  },
  {
    title: 'Campagnes',
    badge: 'CAMPAGNES',
    subtitle: 'Campagnes',
    isSubMenu: true,
    icon: <CampaignIcon />,

    subItems: [
      { title: 'Interstitiels', url: PATHS.Interstiles, badge: 'Interstitiles', subtitle: 'Interstitiles' },
      { title: 'Posts Sponsorisees', url: PATHS.Sponserspost, badge: 'Post Sponsers', subtitle: 'Post Sponsers' },
    ],
  },
  {
    title: 'Annonces',
    badge: 'ANNONCES',
    subtitle: 'Annonces',
    isSubMenu: true,
    icon: <AdsIcon />,
    subItems: [
      { title: 'Annonces en attente', url: PATHS.Pendingads, badge: 'Pending ads', subtitle: 'Pending ads' },
      { title: 'Annonces approuvées', url: PATHS.Approvedads, badge: 'Approved ads', subtitle: 'Approved ads' },
      { title: `Plan d'abonnement`, url: PATHS.AdsSubscriptionPlan, badge: 'Subscription plan', subtitle: 'Subscription plan' },
      { title: 'Comment ça fonctionne', url: PATHS.Howitworks, badge: 'How it works', subtitle: 'How it works' },
    ],
  },
  {
    title: 'Intérêts',
    badge: 'INTERESTS',
    subtitle: 'Interests',
    isSubMenu: true,
    icon: <InterestIcon />,
    subItems: [
      { title: 'Catégories', url: PATHS.Categories, badge: 'Categories', subtitle: 'Categories' },
      { title: 'Intérêts', url: PATHS.Interests, badge: 'InterestsData', subtitle: 'InterestsData' },
    ],
  },
  {
    title: 'Cours de maître',
    badge: 'MASTERCLASS',
    subtitle: 'MasterClass',
    isSubMenu: true,
    icon: <MasterClassIcon />,
    subItems: [
      { title: 'Cours de maître', url: PATHS.MasterClass, badge: 'MASTERCLASSDATA', subtitle: 'MasterClassData' },
      { title: `Plan d'abonnement`, url: PATHS.SubscriptionPlan, badge: 'SUBSCRIPTIONPLAN', subtitle: 'SubscriptionPlan' },
      { title: `Publicités`, url: PATHS.Advertisements, badge: 'ADVERTISEMENTS', subtitle: 'Advertisements' },
    ],
  },
  {
    title: 'Portfolio',
    badge: 'PORTFOLIO',
    subtitle: 'Portfolio',
    isSubMenu: true,
    icon: <PortfolioPlanIcon />,
    subItems: [
      { title: `Plan d'abonnement`, url: PATHS.PortfolioSubscriptionPlan, badge: 'PORTFOLIOSUBSCRIPTIONPLAN', subtitle: 'PortfolioSubscriptionPlan' },
    ],
  },
  // {
  //   title: 'Cours de maître',
  //   url: PATHS.MasterClass,
  //   badge: 'MASTERCLASS',
  //   subtitle: 'MasterClass',
  //   isSubMenu: false,
  //   icon: <MasterClassIcon />,
  // },
  // {
  //   title: `Plan d'abonnement`,
  //   url: PATHS.SubscriptionPlan,
  //   badge: 'SUBSCRIPTIONPLAN',
  //   subtitle: 'SubscriptionPlan',
  //   isSubMenu: false,
  //   icon: <SubscriptionPlanIcon />,
  // },
  // {
  //   title: `Publicités`,
  //   url: PATHS.Advertisements,
  //   badge: 'ADVERTISEMENTS',
  //   subtitle: 'Advertisements',
  //   isSubMenu: false,
  //   icon: <AdsIcon />,
  // },
  {
    title: 'Opportunités',
    url: PATHS.Opportunities,
    badge: 'OPPORTUNITIES',
    subtitle: 'Opportunities',
    isSubMenu: false,
    icon: <OpportunitiesIcon />,
  },
  {
    title: 'Des articles',
    url: PATHS.Articles,
    badge: 'ARTICLES',
    subtitle: 'Articles',
    isSubMenu: false,
    icon: <ArticleIcon />,
  },
  {
    title: 'Base de données',
    url: PATHS.Db,
    badge: 'BASE DE DONNEE',
    subtitle: 'Database',
    isSubMenu: false,
    icon: <DatabaseIcon />,
  },
  {
    title: 'Insectes',
    url: PATHS.Bugs,
    badge: 'BUGS',
    subtitle: 'Bugs',
    isSubMenu: false,
    icon: <BugsIcon />,
  },
  // {
  //   title: 'Universités',
  //   url: PATHS.Universities,
  //   badge: 'UNIVERSITES',
  //   subtitle: 'Universites',
  //   isSubMenu: false,
  //   icon: <UniversityIcon />,
  // },

  {
    title: 'Sous-administrateur',
    url: PATHS.Systemusers,
    badge: 'SYSTEMUSERS',
    subtitle: 'Subadmin',
    isSubMenu: false,
    icon: <SubadminIcon />,
  },
  {
    title: 'Paramètres',
    url: PATHS.Settings,
    badge: 'SETTINGS',
    subtitle: 'Settings',
    isSubMenu: false,
    icon: <SettingsIcon />,
  },
  {
    title: 'Profils',
    url: PATHS.Profiles,
    badge: 'PROFILES',
    subtitle: 'Profiles',
    isSubMenu: false,
    icon: <ProfileIcon />,
  },
];

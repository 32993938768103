/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MasterClassFormProps } from './MasterClass-form.types';
import { theme } from '../../../theme/theme';
import MasterClass from '../../../models/MasterClass';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import FullScreenModal from '../../adds/menubar/approved/form/FullScreenModal';

export default function ViewMasterClass({ currentMasterClass, isUpdate, isEyeIconClicked }: MasterClassFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  const { t } = useTranslation();
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const [adsURL, setAdsURL] = useState<string | undefined>(undefined);
  const [adsType, setAdsType] = useState<string | undefined>(undefined);

  const [largeImgUrl, setLargeImgUrl] = useState<string | undefined>(undefined);
  const [openImgModel, setOpenImgModal] = useState(false);

  const defaultValues = useMemo<MasterClass>(
    () => ({
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '', imageUrl: '' }],
      relatedMasterClasses: currentMasterClass?.relatedMasterClasses || [],
      isPaid: currentMasterClass?.isPaid !== undefined ? currentMasterClass?.isPaid : 1, // Ensure the correct value is set
      MasterClassAd: currentMasterClass?.MasterClassAd || {},
    }),
    [currentMasterClass],
  );
  const methods = useForm<MasterClass>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();
  console.log('currentMasterClass', currentMasterClass?.isPaid);

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentMasterClass]);

  useEffect(() => {
    if (currentMasterClass) {
      const posturl = currentMasterClass?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);

      const addsurl = currentMasterClass?.MasterClassAd?.videoOrImage || '';
      const adsExtension = addsurl.split('.').pop()?.toLowerCase() || '';
      setAdsURL(addsurl);
      setAdsType(adsExtension);
    }
  }, [currentMasterClass]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentMasterClass?.title || '',
      instructorName: currentMasterClass?.instructorName || '',
      description: currentMasterClass?.description || '',
      video: currentMasterClass?.video || '',
      tagIds: currentMasterClass?.tagIds || [],
      learningPoints: currentMasterClass?.learningPoints || [{ point: '', imageUrl: '' }],
      relatedMasterClasses: currentMasterClass?.relatedMasterClasses || [],
      isPaid: currentMasterClass?.isPaid !== undefined ? currentMasterClass?.isPaid : 1, // Ensure the correct value is set
      MasterClassAd: currentMasterClass?.MasterClassAd || {},
    };
    methods.reset(updatedDefaultValues);
  }, [currentMasterClass, isUpdate]);

  const openVideoPopup = (type: string) => {
    if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    } else if (type === 'ads') {
      setVideoURL(adsURL);
      setVideoType(adsType);
    }
    setVideoPopupOpen(true);
  };

  console.log('MasterClassAd', values?.MasterClassAd?.videoOrImage, adsType, adsURL);
  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const handleClickOpenModal = (imageurl: string) => {
    setLargeImgUrl(imageurl || '');
    setOpenImgModal(true);
  };
  const handleClickCloseImgModal = () => {
    setLargeImgUrl('');
    setOpenImgModal(false);
  };

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('masterClass.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.description.placeholder')}</Typography>
                  <label style={customStyle}>{values?.description}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.instructorName.placeholder')}</Typography>
                  <label style={customStyle}>{values?.instructorName}</label>
                </div>

                <div className="col">
                  <Typography>{t('masterClass.form_fields.video.placeholder')}</Typography>

                  {values?.video && (
                    <Grid>
                      <div>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {postURL && (
                            <div
                              style={{
                                width: '100px',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                              }}
                            >
                              <video
                                style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                                src={postURL}
                                onClick={() => openVideoPopup('post')}
                              ></video>
                              <span
                                style={{
                                  backgroundColor: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '9999px',
                                  position: 'absolute',
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  cursor: 'pointer',
                                  justifyContent: 'center',
                                }}
                              >
                                <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}
                </div>
                {values?.MasterClassAd?.videoOrImage && (
                  <Grid>
                    <div>
                      <Typography>{t('masterClass.form_fields.masterClassAds.placeholder')}</Typography>
                      <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                        {adsURL ? (
                          <>
                            <div>
                              <span>
                                {adsType?.includes('mp4') ? (
                                  <>
                                    <div
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                      }}
                                    >
                                      <video
                                        style={{
                                          cursor: 'pointer',
                                          width: '100%',
                                          objectFit: 'cover',
                                          height: '100%',
                                        }}
                                        src={adsURL}
                                        onClick={() => openVideoPopup('ads')}
                                      ></video>

                                      <span
                                        style={{
                                          backgroundColor: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '9999px',
                                          position: 'absolute',
                                          width: '1.5 rem',
                                          height: '1,5 rem',
                                          cursor: 'pointer',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('ads')} />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    src={adsURL}
                                    alt="post"
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleClickOpenModal(adsURL || '')}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                              <label
                                htmlFor="logos"
                                style={{
                                  width: '44px',
                                  height: '44px',
                                  minWidth: '44PX',
                                  backgroundColor: '#f0f0f0',
                                  border: '1px dashed #ccc',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <AddPhotoIcon />
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
                <div className="col">
                  <Typography>{t('masterClass.form_fields.ispaid.placeholder')}</Typography>
                  <label style={customStyle}>{values?.isPaid == 1 ? 'Yes' : 'No'}</label>
                </div>
              </Box>
            </Box>

            {/* Advertisement plan type Information Section */}

            <Box>
              <Typography sx={{ marginBottom: '10px', marginTop: '20px' }}>{t('masterClass.form_fields.learningPoints.placeholder')}</Typography>

              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ paddingTop: '0px' }}>
                    <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'flex-start', gap: '12px', padding: '0', flexWrap: 'wrap' }}>
                      {values.learningPoints?.map((learningPoint, index) => (
                        <li key={index} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <img
                            src={learningPoint?.imageUrl || ''}
                            alt="img"
                            style={{
                              width: '100px',
                              height: '48px',
                              objectFit: 'contain',
                              cursor: 'pointer',
                            }}
                          />
                          <label style={{ textAlign: 'center' }}>{learningPoint.point}</label>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mt: 3, mb: 0 }}>
              <Typography variant="subtitle1">{t('masterClass.form_fields.tags.placeholder')}</Typography>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {values.tagIds?.map((item: any, index) => (
                  <div key={index} style={{ marginTop: '0', marginRight: '10px' }}>
                    <Button variant="outlined" sx={{ cursor: 'not-allowed !important', marginBottom: '7px' }}>
                      {item.name}
                    </Button>
                  </div>
                ))}
              </FormGroup>
            </Box>
            {/* <Box sx={{ mt: 3, mb: 0 }}>
              <Typography variant="subtitle1">{t('masterClass.form_fields.relatedMasterClass.placeholder')}</Typography>
              {values.relatedMasterClasses && values.relatedMasterClasses.length > 0 ? (
                <h1>fsfdsf</h1>
              ) : (
                <label>{t('masterClass.no_related_class')}</label>
              )}
            </Box> */}
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
      {openImgModel && (
        <FullScreenModal
          openImgModel={openImgModel}
          closeImgPopup={handleClickCloseImgModal}
          largeImgUrl={values?.MasterClassAd?.videoOrImage || ''}
        />
      )}
    </>
  );
}
